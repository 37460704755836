<template>
  <div class="wrapper">
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList" >
      <template slot="chgTeamer" slot-scope="{row}">
        <el-button v-if="row.team.teamLeader!=row.volunteer.userId"  size="small" type="text" @click="openChgTeamerDialog(row)">设置为队长</el-button>
        <el-button v-if="row.team.teamLeader==row.volunteer.userId" size="small" type="text" style="color:red;">队长</el-button>
     </template>  
    </CustomList>
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import { relativeTimeThreshold } from 'moment';
export default {
  name: 'VolunteerTeamVolunteer',
  components: { CustomList, ...CustomTemplate },
  data() {
    return {
      reviewStatusArray: ['待审核', '通过', '拒绝'],
      genderArray: ['未知', '男', '女'],
      listQuery: {
        param: {
          teamId: this.$route.params.teamId
        }
      },
      tableColumns: [
        {
          title: '小组名称',
          formatter: (row, column) => {
            return row.team ? row.team.name : ''
          },
          minWidth: 150
        },
        {
          title: '成员名称',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.name : ''
          },
          minWidth: 100
        },
        {
          title: '性别',
          formatter: (row, column) => {
            if (row.volunteer && row.volunteer.gender in this.genderArray) {
              return this.genderArray[row.volunteer.gender]
            } else {
              return ''
            }
          },
          minWidth: 100
        },
        {
          title: '年龄',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.age : ''
          },
          minWidth: 100
        },
        {
          title: '联系方式',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.phone : ''
          },
          minWidth: 150
        },
        {
          title: '地址',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.address : ''
          },
          minWidth: 150
        },
        {
          title: '政治面貌',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.partyMemberName : ''
          },
          minWidth: 150
        },
        {
          title: '学历',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.educationalName : ''
          },
          minWidth: 150
        },
        {
          title: '特长',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.specialtyName : ''
          },
          minWidth: 200
        },
        {
          title: '职业',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.occupationName : ''
          },
          minWidth: 150
        },
        {
          title: '积分余额',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.creditsBalance : ''
          },
          minWidth: 100
        },
        {
          title: '当年总积分',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.currentYearCredits : ''
          },
          minWidth: 100
        },
        {
          title: '总积分',
          formatter: (row, column) => {
            return row.volunteer ? row.volunteer.credits : ''
          },
          minWidth: 100
        },
        {
          title: '状态',
          formatter: (row, column) => {
            if (row.volunteer && row.volunteer.reviewStatus in this.reviewStatusArray) {
              let text = this.reviewStatusArray[row.volunteer.reviewStatus]
              if (row.volunteer.reviewStatus === 2 && row.volunteer.reason) {
                text += `：${row.volunteer.reason}`
              }
              return text
            } else {
              return ''
            }
          },
          minWidth: 150
        },
        {
          prop: 'createTime',
          title: '申请时间',
          minWidth: 160
        },
        {
          title: '操作',
          minWidth: 120,
          fixed: 'right',
          slot:'chgTeamer'
          
        }
      ]
    }
  },
  methods: {
    ...mapActions(['volunteerTeamPublicPageMembersList','volunteerTeamerChange']),
    getList(params, callback) {
      this.volunteerTeamPublicPageMembersList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    openChgTeamerDialog(row){
     
      //更换志愿者团队 队长的 方法
      this.$alert('你确定要设置 '+row.volunteer.name+" 为团队的队长吗？", '提示', {
          confirmButtonText: '确定',
          callback: action => {
              console.log("row====",row)
              //接口
              this.volunteerTeamerChange({'teamId':row.teamId,'teamerId':row.memberUserId}).then(response => {
                this.$message.success('设置成功')
      
                location.reload(0)
              }).catch(() => {})


          }
        });
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
